body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --yellow-1: #fdd800;
  --yellow-2: #f3c300;
  --orange-1: #ffa400;
  --orange-2: #ff671d;
  --dark-grey: #4f4f4f;
  --light-grey: #dbdbdb;
  --light-grey-half: #ededed;
  --blue-1: #0cb5ff;
  --blue-2: #0071e9;
  --green-1: #6ed42b;
  --green-2: #18a223;
  --gradient-orange: linear-gradient(5deg,var(--yellow-2), var(--orange-2));
  --gradient-blue: linear-gradient(5deg,var(--blue-1), var(--blue-2));
  --gradient-green: linear-gradient(5deg,var(--green-1), var(--green-2));
}

*{
  font-family: 'Montserrat';
  color: var(--dark-grey);
  font-size: 13px;

}

*{
  box-sizing: border-box;
}

h1, h2, h3{
  font-family: 'Montserrat';
  font-weight: bold;

}

