#selector{
    display: flex;
    flex-direction: column;
}

#selector select{
    cursor: pointer; 
    height: 2rem;
}

@media only screen and (max-width: 840px) {
    #selector{
      display: flex;
      flex-direction: row;
    }
  }