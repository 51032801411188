#side-menu{
    z-index: 30;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.2);
    /* opacity: 0.2; */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.19);
    /*grid-area: menu;
    padding:0.5rem; */
}

#search-card{
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}


#search-card-close{
  box-sizing: border-box;
  width: 100%;
  padding: 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
#search-card-close button{
  cursor: pointer; 
}


@media only screen and (max-width: 840px) {
    #side-menu{
      margin-bottom: 10px;
    }
  }